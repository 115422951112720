import React, { useState, useEffect, useRef } from "react";
import "./css/style.css";
import animat from "../../assets/img/Запись экрана 2024-12-03 в 15.21.11 1.svg";
import solana from "../../assets/img/Clip path group.svg";
import arrow from "../../assets/img/image 2 (Traced).svg";
import { showToastSuccess } from "../ToastPopup";
import { levels } from "../../data/data";

const AppLevel = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [updatedLevels, setUpdatedLevels] = useState(levels);
  const [activeLevel, setActiveLevel] = useState(1);
  const [activeLevelData, setActiveLevelData] = useState(levels[0]);
  const levelsRef = useRef(null);
  const popupRef = useRef(null);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleLevelClick = (level) => {
    if (level.status !== "CLOSED") {
      setActiveLevel(level.level);
      setActiveLevelData(level);
      setIsOpen(false);
    }
  };

  const handleBuyClick = () => {
    if (!activeLevelData.purchased) {
      const newLevels = updatedLevels.map((level) =>
        level.level === activeLevel ? { ...level, purchased: true } : level
      );
      setUpdatedLevels(newLevels);
      setActiveLevelData({ ...activeLevelData, purchased: true });
      showToastSuccess(`Level ${activeLevel} purchased successfully!`);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const newLevels = updatedLevels.map((level) => {
        const startDate = new Date(level.start);
        const timeDiff = startDate - now;
        if (timeDiff <= 0 && level.status === "CLOSED") {
          return { ...level, status: "OPEN", time: "00:00:00" };
        } else if (level.status === "CLOSED") {
          const hours = Math.floor(timeDiff / (1000 * 60 * 60));
          const minutes = Math.floor(
            (timeDiff % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
          return {
            ...level,
            time: `${hours.toString().padStart(2, "0")}:${minutes
              .toString()
              .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`,
          };
        }
        return level;
      });
      setUpdatedLevels(newLevels);
    }, 1000);

    return () => clearInterval(interval);
  }, [updatedLevels]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        levelsRef.current &&
        !levelsRef.current.contains(event.target) &&
        popupRef.current &&
        !popupRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="app-level">
      <div className="app-level__container">
        <div className="app-level__game">
          <img src={animat} alt="Game" className="app-level__game-img" />
          <div className="app-level__game-info">
            <ul className="app-level__game-list">
              <li className="app-level__game-item">
                <span className="app-level__game-text">
                  Potential{" "}
                  <span className="app-level__highlight">Profit:</span>
                </span>
                <span className="game-text__bold app-level__game-text ">
                  <img
                    src={solana}
                    alt="Solana"
                    className="app-level__game-icon"
                  />
                  {` `}
                  {activeLevelData.profit}
                  {` `}
                  <span className="app-level__game-text">SOL</span>
                </span>
              </li>
              <li className="app-level__game-item">
                <span className="app-level__game-text  app-level__game-text-missed">
                  Missed:
                </span>
                <span className="game-text__bold app-level__game-text ">
                  <img
                    src={solana}
                    alt="Solana"
                    className="app-level__game-icon"
                  />
                  {` `}
                  {activeLevelData.missed}{" "}
                  <span className="app-level__game-text">SOL</span>
                </span>
              </li>
            </ul>
          </div>
          <div className="app-level__game-btns">
            <button
              className="app-level__game-btn"
              onClick={handleBuyClick}
              style={{
                backgroundColor: activeLevelData.purchased
                  ? "#1F1F1F"
                  : "#DE25CB",
              }}
            >
              {activeLevelData.purchased ? "OWNED" : "BUY"}
            </button>
          </div>
        </div>
        <div className="app-level__levels">
          <ul className="app-level__levels-list">
            {updatedLevels.map((level) => (
              <li
                key={level.level}
                className={`app-level__levels-item ${
                  level.status === "CLOSED" ? "close-levels" : ""
                } ${activeLevel === level.level ? "active-lvl" : ""}`}
                onClick={() => handleLevelClick(level)}
              >
                <span
                  className={`app-level__levels-text app-level__levels-title ${
                    level.status === "CLOSED" ? "close" : ""
                  }`}
                >
                  <span
                    style={{
                      display:
                        level.status === "CLOSED" ? "none" : "inline-block",
                    }}
                    className={`checkbox-circle ${
                      level.purchased ? "checked" : ""
                    } ${level.status === "CLOSED" ? "disabled" : ""}`}
                  ></span>
                  Level {level.level}
                </span>
                <span className="app-level__levels-text app-level__levels-time">
                  {level.status === "CLOSED" ? level.time : level.status}
                </span>
              </li>
            ))}
          </ul>
        </div>
        <div className="app-level__levels-phone">
          <div
            className="app-level__levels-popup"
            onClick={toggleOpen}
            ref={popupRef}
          >
            <span className="app-level__levels-text">Level {activeLevel}</span>
            <span className="app-level__levels-text othet">
              <img
                src={arrow}
                alt="Solana"
                className="app-level__game-icon"
                style={{
                  transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 0.3s ease",
                }}
              />
              Other
            </span>
          </div>
          <div
            className="app-level__levels-mobile"
            style={{
              height: isOpen ? "361px" : "0px",
              overflow: "hidden",
              transition: "height 0.5s ease",
            }}
            ref={levelsRef}
          >
            <ul className="app-level__levels-list-mobile">
              {updatedLevels.map((level) => (
                <li
                  key={level.level}
                  className={`app-level__levels-item-mobile ${
                    level.status === "CLOSED" ? "close-levels-mobile" : ""
                  } ${activeLevel === level.level ? "active-lvl" : ""}`}
                  onClick={() => handleLevelClick(level)}
                >
                  <div
                    className={`app-level__levels-text-mobile app-level__levels-title-mobile ${
                      level.status === "CLOSED" ? "close" : ""
                    }`}
                  >
                    <span
                      style={{
                        display:
                          level.status === "CLOSED" ? "none" : "inline-block",
                      }}
                      className={`checkbox-circle ${
                        level.purchased ? "checked" : ""
                      } ${level.status === "CLOSED" ? "disabled" : ""}`}
                    ></span>
                    Level {level.level}
                  </div>
                  <div
                    className={`app-level__levels-text-mobile ${
                      level.status === "CLOSED"
                        ? "app-level__levels-time-mobile"
                        : ""
                    }`}
                  >
                    {level.status === "CLOSED" ? level.time : level.status}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLevel;
