import React from "react";
import "./css/style.css";
import title from "../../assets/img/solnet_full_text_logo_svg.svg";
import { Link } from "react-router-dom";

const WelcomeComponent = () => {
  return (
    <>
      <div className="WelcomeComponent">
        <div className="welcome-title">
          <img src={title} alt="logo" />
        </div>
        <div className="welcome-content">
          A financial instrument based on Web3,
          <br />
          leveraging artificial intelligence to
          <br />
          create and manage investment
          <br />
          strategies.
        </div>
      </div>
      <div className="welcome-button">
        <Link to="/levels" className="btn btn-primary">
          LAUNCH APP
        </Link>
      </div>
    </>
  );
};

export default WelcomeComponent;
