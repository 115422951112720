export const levels = [
  {
    level: 1,
    profit: 1203.79,
    start: "2024-12-26T13:00:00",
    missed: 3.21,
    status: "OPEN",
    time: "0",
    purchased: true,
  },
  {
    level: 2,
    profit: 1300.05,
    start: "2024-12-26T13:00:00",
    missed: 3.5,
    status: "OPEN",
    time: "0",
    purchased: false,
  },
  {
    level: 3,
    profit: 1430.032,
    start: "2024-12-26T13:00:00",
    missed: 3.6,
    status: "OPEN",
    time: "0",
    purchased: false,
  },
  {
    level: 4,
    profit: 1500.05,
    start: "2024-12-25T13:00:00",
    missed: 3.8,
    status: "CLOSED",
    time: "Loading...",
    purchased: false,
  },
  {
    level: 5,
    profit: 1600.05,
    start: "2024-12-26T13:00:00",
    missed: 3.8,
    status: "CLOSED",
    time: "Loading...",
    purchased: false,
  },
  {
    level: 6,
    profit: 1700.05,
    start: "2024-12-27T13:00:00",
    missed: 4.0,
    status: "CLOSED",
    time: "Loading...",
    purchased: false,
  },
];

export const historyData = [
  {
    address: "0x40f887331d6",
    action: "Buy LvL4 GPU",
    time: "16 hrs ago",
  },
  {
    address: "0x40f887331d6",
    action: "Buy LvL4 GPU",
    time: "16 hrs ago",
  },
  {
    address: "0x40f887331d6",
    action: "Buy LvL4 GPU",
    time: "16 hrs ago",
  },
  {
    address: "0x40f887331d6",
    action: "Buy LvL4 GPU",
    time: "16 hrs ago",
  },
  {
    address: "0x40f887331d6",
    action: "Buy LvL4 GPU",
    time: "16 hrs ago",
  },
  {
    address: "0x40f887331d6",
    action: "Buy LvL4 GPU",
    time: "16 hrs ago",
  },
  {
    address: "0x40f887331d6",
    action: "Buy LvL4 GPU",
    time: "16 hrs ago",
  },
  {
    address: "0x40f887331d6",
    action: "Buy LvL4 GPU",
    time: "16 hrs ago",
  },
  {
    address: "0x40f887331d6",
    action: "Buy LvL4 GPU",
    time: "16 hrs ago",
  },
  {
    address: "0x40f887331d6",
    action: "Buy LvL4 GPU",
    time: "16 hrs ago",
  },
  {
    address: "0x40f887331d6",
    action: "Buy LvL4 GPU",
    time: "16 hrs ago",
  },
  {
    address: "0x40f887331d6",
    action: "Buy LvL4 GPU",
    time: "16 hrs ago",
  },
];

export const referralData = [
  {
    address: "0x123...abc",
    totalProfit: "10.5 SOL",
    invitedUsers: 5,
  },
  {
    address: "0x456...def",
    totalProfit: "8.2 SOL",
    invitedUsers: 3,
  },
  {
    address: "0x789...ghi",
    totalProfit: "12.3 SOL",
    invitedUsers: 7,
  },
  {
    address: "0xabc...jkl",
    totalProfit: "15.0 SOL",
    invitedUsers: 10,
  },
  {
    address: "0xdef...mno",
    totalProfit: "9.8 SOL",
    invitedUsers: 4,
  },
  {
    address: "0xghi...pqr",
    totalProfit: "11.1 SOL",
    invitedUsers: 6,
  },
];
