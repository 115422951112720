import React, { useState } from "react";
import "./css/style.css";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const generateRandomData = (baseData, length) => {
  const data = [];
  const totalSteps = length;
  const pointsPerSegment = Math.floor(totalSteps / (baseData.length - 1));

  for (let i = 0; i < baseData.length - 1; i++) {
    const start = baseData[i];
    const end = baseData[i + 1];
    const stepSize = (end - start) / pointsPerSegment;
    for (let j = 0; j < pointsPerSegment; j++) {
      const fluctuation = Math.random() * 0.2 - 0.1;
      const value = start + j * stepSize + start * fluctuation;
      data.push(Math.round(value));
    }
  }

  // Добавляем недостающие точки, если необходимо
  while (data.length < length) {
    data.push(baseData[baseData.length - 1]);
  }

  return data.slice(0, length);
};

const generateRandomDates = () => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dates = [];
  const today = new Date();

  // Вычисляем дату два дня назад
  const twoDaysAgo = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 2
  );

  // Вычисляем дату шесть месяцев назад
  const sixMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 5, 1);

  let currentDate = new Date(sixMonthsAgo);

  while (currentDate <= twoDaysAgo) {
    const monthName = months[currentDate.getMonth()];
    const day = currentDate.getDate();
    dates.push(`${monthName} ${day}`);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};

const StatisticsChart = () => {
  const [activeDataset, setActiveDataset] = useState("global");

  const randomDates = generateRandomDates();
  const globalData = generateRandomData(
    [100, 15000, 30000, 20000, 50000, 40000],
    randomDates.length
  );
  const personalData = generateRandomData(
    [50, 500, 1000, 800, 1200, 900],
    randomDates.length
  );
  const datasets = {
    global: {
      label: "Global Statistics",
      data: globalData,
      backgroundColor: (context) => {
        const chart = context.chart;
        const { ctx, chartArea } = chart;

        if (!chartArea) {
          return null;
        }

        const gradient = ctx.createLinearGradient(
          chartArea.left,
          0,
          chartArea.right,
          0
        );
        gradient.addColorStop(0, "rgba(0, 0, 0, 1)");
        gradient.addColorStop(0.5, "rgba(0, 0, 0, 1)");
        gradient.addColorStop(1, "rgba(222, 37, 203, 0.5)");

        return gradient;
      },
      borderColor: "rgba(222, 37, 203, 1)",
      borderWidth: 2,
      fill: true,
      pointRadius: 0,
      pointHoverRadius: 8,
      pointHoverBackgroundColor: "rgba(222, 37, 203, 1)",
      pointHoverBorderColor: "rgba(222, 37, 203, 1)",
      tension: 0.4,
    },
    personal: {
      label: "Personal Statistics",
      data: personalData,
      backgroundColor: (context) => {
        const chart = context.chart;
        const { ctx, chartArea } = chart;

        if (!chartArea) {
          return null;
        }

        const gradient = ctx.createLinearGradient(
          chartArea.left,
          0,
          chartArea.right,
          0
        );
        gradient.addColorStop(0, "rgba(0, 0, 0, 1)");
        gradient.addColorStop(0.5, "rgba(0, 0, 0, 1)");
        gradient.addColorStop(1, "rgba(255, 255, 255, 0.5)");

        return gradient;
      },
      borderColor: "rgba(255, 255, 255, 1)",
      borderWidth: 2,
      fill: true,
      pointRadius: 0,
      pointHoverRadius: 8,
      pointHoverBackgroundColor: "rgba(255, 255, 255, 1)",
      pointHoverBorderColor: "rgba(255, 255, 255, 1)",
      tension: 0.4,
    },
  };

  const data = {
    labels: randomDates,
    datasets: [datasets[activeDataset]],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        mode: "index",
        intersect: false,
        callbacks: {
          label: function (tooltipItem) {
            return `Value: ${tooltipItem.raw}`;
          },
        },
      },
      hover: {
        mode: "index",
        intersect: false,
      },
    },
    scales: {
      x: {
        display: true,
        ticks: {
          color: "#8C8C8C",
          className: "chart-x-axis",
          font: {
            family: "Gantari",
            size: 14,
            weight: "400",
          },
          autoSkip: false, // Добавлено, чтобы не пропускать метки
          maxRotation: 0, // Добавлено, чтобы предотвратить вращение меток
          callback: function (value, index) {
            const label = data.labels[index];
            if (label.endsWith(" 1")) {
              return label;
            }
            return "";
          },
        },
        max: data.labels.length - 1,
      },
      y: {
        display: true,
        ticks: {
          color: "#8C8C8C",
          className: "chart-y-axis",
          font: {
            family: "Gantari",
            size: 14,
            weight: "400",
          },
          callback: function (value) {
            return value;
          },
        },
      },
    },
    interaction: {
      mode: "nearest",
      intersect: false,
      axis: "x",
    },
  };

  return (
    <div className="chart-container">
      <div className="chart-header">
        <div className="chart-title">
          <span className="chart-title-text">Global Revenue</span>
          <span className="chart-title-subtext">
            <span className="app-account__info-text-bold">50</span>
            SOL
          </span>
        </div>

        <div className="chart-buttons">
          <div
            onClick={() => setActiveDataset("global")}
            className="chart-button"
            style={{
              backgroundColor:
                activeDataset === "global" ? "#080808" : "#343434",
              color: activeDataset === "global" ? "#F2F2F2" : "#8C8C8C",
            }}
          >
            All
          </div>
          <div
            onClick={() => setActiveDataset("personal")}
            className="chart-button"
            style={{
              backgroundColor:
                activeDataset === "personal" ? "#080808" : "#343434",
              color: activeDataset === "personal" ? "#F2F2F2" : "#8C8C8C",
            }}
          >
            Your
          </div>
        </div>
        <div className="chart-data">
          {new Date().toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })}
        </div>
      </div>
      <Line data={data} options={options} />
    </div>
  );
};

export default StatisticsChart;
