import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import confetti from "canvas-confetti";

const ToastNotification = () => {
  return <ToastContainer />;
};

export const showToastSuccess = (message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: {
      backgroundColor: "#1f1f1f",
      color: "#F2F2F2",
      fontFamily: "Gantari",
      fontSize: "16px",
      fontWeight: "600",
      lineHeight: "19.2px",
      textAlign: "left",
      textUnderlinePosition: "from-font",
      textDecorationSkipInk: "none",
      zIndex: 9999, // Убедитесь, что тосты отображаются поверх других элементов
    },
    onOpen: () => {
      confetti({
        particleCount: 100,
        spread: 160, // Уменьшенный радиус взрыва
        startVelocity: 30, // Начальная скорость конфетти
        origin: { x: 0.98, y: 0.1 }, // Позиция конфетти в правом верхнем углу
        colors: ["#DE25CB"], // Розовый цвет конфетти
        zIndex: 9998, // Убедитесь, что конфетти отображаются за тостами
      });
    },
  });
};

export const showToastError = (message) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: {
      backgroundColor: "#1f1f1f",
      color: "#F2F2F2",
      fontFamily: "Gantari",
      fontSize: "16px",
      fontWeight: "600",
      lineHeight: "19.2px",
      textAlign: "left",
      textUnderlinePosition: "from-font",
      textDecorationSkipInk: "none",
      zIndex: 9999, // Убедитесь, что тосты отображаются поверх других элементов
    },
  });
};

export default ToastNotification;
