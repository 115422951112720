import React from "react";
import "./css/style.css";

import frog from "../../assets/img/bd3232e7ca9343c0e6132f468558cea5.png";

const Footer = () => {
  return (
    <footer className="Footer" id="contact">
      <img src={frog} alt="nxt watch logo" />
      <div className="Footer-content">
        <h2 className="Footer-title">Contact</h2>
        <a href="mailto:smokemycherry@gmail.com" className="Footer-link">
          smokemycherry@gmail.com
        </a>
        <a href="" className="Footer-link">
          Twitter
        </a>
        <a href="" className="Footer-link">
          Discord
        </a>
      </div>
    </footer>
  );
};

export default Footer;
