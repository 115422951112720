import React, { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import slider1 from "../../assets/img/IMG_1801.MOV";
import slider2 from "../../assets/img/IMG_1799.MOV";
import slider3 from "../../assets/img/IMG_1802.MOV";
import slider4 from "../../assets/img/IMG_1800.MOV";
import { Autoplay } from "swiper/modules";

import "swiper/css";

const CustomSlider = () => {
  const videoRefs = useRef([]);
  const swiperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const slides = [slider1, slider2, slider3, slider4];
  const slideTitles = [
    "Project Overview",
    "Technology Foundation",
    "Vision",
    "Fair Distribution",
  ];

  const handleSlideChange = (swiper) => {
    const current = swiper.realIndex;
    setActiveIndex(current);
  };

  const handleButtonClick = (index) => {
    if (swiperRef.current) {
      swiperRef.current.slideToLoop(index);
    }
    setActiveIndex(index);
    videoRefs.current.forEach((video, idx) => {
      if (idx === index) {
        video.currentTime = 0;
        video.play();
      } else {
        video.pause();
      }
    });
  };

  return (
    <>
      <h1 className="technology-title">{slideTitles[activeIndex]}</h1>
      <div className="technology-sliderButtons">
        {slides.map((_, index) => (
          <button
            key={index}
            className={`technology-sliderButton ${
              activeIndex === index ? "active" : ""
            }`}
            onClick={() => handleButtonClick(index)}
          />
        ))}
      </div>
      <Swiper
        onSlideChange={handleSlideChange}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        loop={true}
        autoplay={{
          delay: 8300,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        pagination={{ clickable: true }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <video
              ref={(el) => (videoRefs.current[index] = el)}
              src={slide}
              muted
              loop
              autoPlay
              playsInline
              style={{ width: "100%" }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default CustomSlider;
