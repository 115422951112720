import "./App.css";
import "./assets/css/style.css";
import { Route, Routes } from "react-router-dom";
import About from "./components/About";
import AppHeader from "./components/AppHeader";
import AppLevel from "./components/AppLevel";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LastWinner from "./components/LastWinner";
import Questions from "./components/Questions";
import RoadMap from "./components/RoadMap";
import WelcomeComponent from "./components/WelcomComponent";
import AppAccount from "./components/AppAccount";
import AppStatistics from "./components/AppStatistics";
import "react-toastify/dist/ReactToastify.css";
import React from "react";
import {
  WalletProvider,
  ConnectionProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { BackpackWalletAdapter } from "@solana/wallet-adapter-backpack";
import { clusterApiUrl } from "@solana/web3.js";
import "@solana/wallet-adapter-react-ui/styles.css";
import ToastNotification from "./components/ToastPopup";
import Head from "./components/Head/head";

const wallets = [
  new PhantomWalletAdapter(),
  new SolflareWalletAdapter(),
  new BackpackWalletAdapter(),
];

export const PageWrapper = ({ children }) => {
  return (
    <>
      <AppHeader />
      <LastWinner />
      {children}
    </>
  );
};

export const formatAddress = (address) => {
  if (!address) return "";
  const addressStr = address.toString();
  return `${addressStr.slice(0, 6)}...${addressStr.slice(-4)}`;
};

function App() {
  return (
    <div className="App">
      <Head />
      <ConnectionProvider endpoint={clusterApiUrl("mainnet-beta")}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <div className="Welcom">
                      <Header />
                      <WelcomeComponent />
                    </div>
                    <About />
                    <RoadMap />
                    <Questions />
                    <Footer />
                  </>
                }
              />

              <Route
                path="/levels"
                element={
                  <PageWrapper>
                    <AppLevel />
                  </PageWrapper>
                }
              />
              <Route
                path="/account"
                element={
                  <PageWrapper>
                    <AppAccount />
                  </PageWrapper>
                }
              />
              <Route
                path="/statistics"
                element={
                  <PageWrapper>
                    <AppStatistics />
                  </PageWrapper>
                }
              />
            </Routes>
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
      <ToastNotification />
    </div>
  );
}

export default App;
