import React from "react";
import { Helmet } from "react-helmet";

import logo from "../../assets/img/logo-mono-white.svg";

const Head = () => {
  return (
    <Helmet>
      <title>Solnet AI - AI-Powered Investment Strategies</title>
      <meta
        name="description"
        content="Solnet is a financial instrument based on Web3, leveraging artificial intelligence to create and manage investment strategies. Experience transparent and secure operations with full control over your assets."
      />
      <link rel="icon" type="image/png" href={logo} />
    </Helmet>
  );
};

export default Head;
