import React from "react";
import "./css/style.css";
import arrow from "../../assets/img/image 3 (Traced).svg";
import StatisticsChart from "../StatisticsChart";
import { formatAddress } from "../../App";
import { historyData } from "../../data/data";

const AppStatistics = () => {

  return (
    <div className="app-statistics">
      <div className="app-statistics__container">
        <div className="app-statistics__top-stats">
          <div className="app-statistics__stat">
            <p className="app-statistics__stat-title">
              Total Transaction in Protocol
            </p>
            <p className="app-statistics__stat-value">
              <span className="app-account__info-text-bold">94,329</span> tx
            </p>
            <p className="app-statistics__stat-text">December 2, 2024</p>
          </div>
          <div className="app-statistics__stat">
            <p className="app-statistics__stat-title">Members in 24 hours</p>
            <p className="app-statistics__stat-value">
              <span className="app-account__info-text-bold">6,731</span> users
            </p>
            <p className="app-statistics__stat-text">December 2, 2024</p>
          </div>
          <div className="app-statistics__stat">
            <p className="app-statistics__stat-title">Turnover, SOL</p>
            <p className="app-statistics__stat-value">
              <span className="app-account__info-text-bold">07,34</span> SOL
            </p>
            <p className="app-statistics__stat-text">December 2, 2024</p>
          </div>
        </div>
        <div className="app-statistics__bottom-stats">
          <div className="app-statistics__diagram">
            <StatisticsChart />
          </div>
          <div className="app-account__info-history app-statistics__live-history">
            <div className="app-account__info-history-title">
              <span className="app-account__info-text-title">Live feed:</span>
            </div>

            <ul className="app-account__info-history-list">
              {historyData.map((item, index) => (
                <li className="app-account__info-history-item" key={index}>
                  <span className="app-account__info-text">
                    {formatAddress(item.address)}
                  </span>
                  <a
                  href=""
                  className="app-account__info-text app-account__info-text-highlight">
                    {item.action}
                    <img
                      src={arrow}
                      alt="Solana"
                      className="app-account__info-icon"
                    />
                  </a>
                  <span className="app-account__info-text">{item.time}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppStatistics;
