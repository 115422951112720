import React, { useState, useEffect, useRef } from "react";
import "./css/style.css";
import copy from "../../assets/img/image 3 (Traced).svg";

const generateRandomWinner = () => ({
  address: `0x${Array.from(
    { length: 40 },
    () => "0123456789abcdef"[Math.floor(Math.random() * 16)]
  ).join("")}`,
  status: "Receiving Rewards",
  amount: `${(Math.random() * 1).toFixed(2)} SOL`,
  time: `${Math.floor(Math.random() * 60)} secs ago`,
});

const LastWinner = () => {
  const maxBlocks = 5;
  const [winnersData, setWinnersData] = useState(
    Array.from({ length: maxBlocks }, generateRandomWinner)
  );
  const containerRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setWinnersData((prev) => [...prev.slice(1), generateRandomWinner()]);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    let isDown = false,
      startX,
      scrollLeft;

    const mouseDownHandler = (e) => {
      isDown = true;
      startX = e.pageX - container.offsetLeft;
      scrollLeft = container.scrollLeft;
    };

    const mouseMoveHandler = (e) => {
      if (!isDown) return;
      e.preventDefault();
      container.scrollLeft =
        scrollLeft - (e.pageX - container.offsetLeft - startX) * 3;
    };

    container.addEventListener("mousedown", mouseDownHandler);
    container.addEventListener("mouseleave", () => (isDown = false));
    container.addEventListener("mouseup", () => (isDown = false));
    container.addEventListener("mousemove", mouseMoveHandler);

    return () => {
      container.removeEventListener("mousedown", mouseDownHandler);
      container.removeEventListener("mouseleave", () => (isDown = false));
      container.removeEventListener("mouseup", () => (isDown = false));
      container.removeEventListener("mousemove", mouseMoveHandler);
    };
  }, []);

  return (
    <div className="last-winner">
      <div className="last-winner__contentBox" ref={containerRef}>
        {winnersData.map((winner, index) => (
          <div className="last-winner__content" key={index}>
            <span className="last-winner__contentText">
              {winner.address.slice(0, 13)}...
            </span>

            <a
              href=""
              className="last-winner__contentText last-winner__content-link"
            >
              <img src={copy} alt="Copy" className="last-winner__copy" />
              {winner.status}
            </a>
            <span className="last-winner__contentText">{winner.amount}</span>
            <span className="last-winner__contentText">{winner.time}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LastWinner;
