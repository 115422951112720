import React, { useState, useEffect } from "react";
import "./css/style.css";
import logo from "../../assets/img/logo-mono-white.svg";
import burger from "../../assets/img/but 1.svg";
import { Link } from "react-router-dom";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleScroll = (event, targetId) => {
    event.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (
      isMenuOpen &&
      !event.target.closest(".header-nav-list") &&
      !event.target.closest(".burger-menu")
    ) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <header className="header" id="home">
      <Link to="/" className="header-logo">
        <img src={logo} alt="Solana Logo" />
      </Link>
      <nav
        className={`header-nav ${isMenuOpen ? "open" : ""}`}
        onClick={handleClickOutside}
      >
        <ul className="header-nav-list">
          <li className="header-nav-item">
            <a
              href="#home"
              className="header-nav-link"
              onClick={(e) => handleScroll(e, "home")}
            >
              Home
            </a>
          </li>
          <li className="header-nav-item">
            <a
              href="#about"
              className="header-nav-link"
              onClick={(e) => handleScroll(e, "about")}
            >
              About us
            </a>
          </li>
          <li className="header-nav-item">
            <a
              href="#faq"
              className="header-nav-link"
              onClick={(e) => handleScroll(e, "faq")}
            >
              FAQ
            </a>
          </li>
          <li className="header-nav-item">
            <a
              href="#contact"
              className="header-nav-link"
              onClick={(e) => handleScroll(e, "contact")}
            >
              Contact
            </a>
          </li>
        </ul>
      </nav>
      <div className="burger-menu" onClick={toggleMenu}>
        <img src={burger} alt="Burger Menu" />
      </div>
    </header>
  );
};

export default Header;
