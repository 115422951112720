import React, { useEffect, useState } from "react";
import {
  useWallet,
  WalletNotSelectedError,
} from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { showToastError } from "../ToastPopup";
import { Connection } from "@solana/web3.js";

const RPC_URL =
  "https://solana-mainnet.g.alchemy.com/v2/Ru5vav4eLLLq0XgXm-C8M_9jUxlCKz9X";

const ConnectWalletButton = () => {
  const { connected, publicKey, disconnect } = useWallet();
  const [balance, setBalance] = useState(null);
  const [hasWalletConnectionError, setHasWalletConnectionError] =
    useState(false);

  const fetchBalance = async () => {
    if (publicKey) {
      try {
        console.log(`Fetching balance for publicKey: ${publicKey.toString()}`);
        const connection = new Connection(RPC_URL, "confirmed");
        const rawBalance = await connection.getBalance(publicKey);
        console.log(`Raw balance: ${rawBalance}`);
        const solBalance = (rawBalance / 1e9).toFixed(3);
        console.log(`SOL balance: ${solBalance}`);
        setBalance(solBalance);
      } catch (error) {
        console.error("Failed to fetch balance:", error.message);
        showToastError("Failed to fetch wallet balance.");
        setBalance(null);
      }
    }
  };

  useEffect(() => {
    fetchBalance();
  }, [publicKey]);

  useEffect(() => {
    const originalConsoleError = console.error;
    console.error = (...args) => {
      console.log("args", args);
      if (
        args.some(
          (arg) => arg instanceof Error && arg.name === "WalletNotReadyError"
        )
      ) {
        setHasWalletConnectionError(true);
        disconnect();
        showToastError(
          "Wallet Not Ready. Please install the wallet extension."
        );
      }
      originalConsoleError(...args);
    };
    return () => {
      console.error = originalConsoleError;
    };
  }, [disconnect]);

  return (
    <div className={connected ? "wallet-connected" : "wallet-not-connected"}>
      <WalletMultiButton>
        {connected
          ? balance !== null
            ? `${balance} SOL`
            : "Loading..."
          : "Connect Wallet"}
      </WalletMultiButton>
    </div>
  );
};

export default ConnectWalletButton;
