import React, { useEffect } from "react";

const BouncingItems = ({ containerRef }) => {
  useEffect(() => {
    const container = containerRef.current;
    const swimItems = container.querySelectorAll(".swim-item");

    swimItems.forEach((item) => {
      item.style.position = "absolute";

      let x = Math.random() * (container.clientWidth - item.clientWidth);
      let y = Math.random() * (container.clientHeight - item.clientHeight);
      let speedX = (Math.random() - 0.5) * 4;
      let speedY = (Math.random() - 0.5) * 4;
      let isPaused = false;

      item.style.left = `${x}px`;
      item.style.top = `${y}px`;

      const moveItem = () => {
        if (!isPaused) {
          x += speedX;
          y += speedY;

          if (x <= 0) {
            x = 0;
            speedX *= -1;
          }
          if (x + item.clientWidth >= container.clientWidth - 4) {
            x = container.clientWidth - item.clientWidth - 4;
            speedX *= -1;
          }
          if (y <= 0) {
            y = 0;
            speedY *= -1;
          }
          if (y + item.clientHeight >= container.clientHeight) {
            y = container.clientHeight - item.clientHeight;
            speedY *= -1;
          }

          item.style.left = `${x}px`;
          item.style.top = `${y}px`;
        }

        requestAnimationFrame(moveItem);
      };

      item.addEventListener("mouseenter", () => {
        isPaused = true;
      });

      item.addEventListener("mouseleave", () => {
        isPaused = false;
      });

      moveItem();
    });
  }, []);

  return (
    <>
      <div className="swim-item small-swim-item">
        WEB
        <br />3<span className="swim-itemText">World Wide Web 3.0</span>
      </div>
      <div className="swim-item">
        C<span className="swim-itemText">Crypto</span>
      </div>
      <div className="swim-item">
        AI<span className="swim-itemText">Artificial Intelligence</span>
      </div>
    </>
  );
};

export default BouncingItems;
