import React from "react";
import "./css/style.css";

const RoadMap = () => {
  const roadMapData = [
    {
      title: "Phase 1: Foundation Setup",
      texts: [
        "Develop AI-based fair distribution algorithms",
        "Conduct security audits and testing",
      ],
    },
    {
      title: "Phase 2: Community Growth",
      texts: [
        "Introduce community engagement programs",
        "Begin partnerships with industry leaders",
      ],
    },
    {
      title: "Phase 3: Scaling",
      texts: [
        "Roll out full platform access with open investment options",
        "Expand marketing to drive adoption",
        "Launch profit-sharing and reinvestment features",
      ],
    },
    {
      title: "Phase 4: Platform Expansion",
      texts: [
        "Integrate advanced AI models for enhanced decision-making",
        "Enable multi-chain support and cross-ecosystem compatibility",
        "Release governance features for community voting",
      ],
    },
    {
      title: "Phase 5: Ecosystem Development",
      texts: [
        "Launch additional AI agent features for increased project adaptability",
        "Continue community-led innovation and platform upgrades",
      ],
    },
  ];

  return (
    <div className="RoadMap" id="roadmap">
      <div className="RoadMap-title">
        <h1 className="RoadMap-title-text">RoadMap</h1>
        <h2 className="RoadMap-title-subtext">
          Here's a concise roadmap for
          <br />
          Solnet AI:
        </h2>
      </div>
      <div className="RoadMap-contentBox">
        {roadMapData.map((item, index) => {
          const [phase, number, ...rest] = item.title.split(" ");
          return (
            <div className="RoadMap-content" key={index}>
              <div className="RoadMap-contentTitle">
                <h1>
                  {phase} <span className="highlight-number">{number}</span>{" "}
                  {rest.join(" ")}
                </h1>
              </div>
              <div className="RoadMap-contentText">
                {item.texts.map((text, textIndex) => (
                  <p key={textIndex}>- {text}</p>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RoadMap;
