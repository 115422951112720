import React from "react";
import "./css/style.css";

const Questions = () => {
  const questionsData = [
    {
      title: "What is Solnet?",
      text: "Solnet is a financial instrument based on Web3, leveraging artificial intelligence to create and manage investment strategies.",
    },
    {
      title: "How does Solnet use AI?",
      text: "Our AI algorithm detects anomalous transactions and user behavior by analyzing vast amounts of data and identifying potential threats in real-time.",
    },
    {
      title: "What are the benefits of using Solnet?",
      text: "Solnet provides transparent and secure operations, giving users confidence and full control over their assets.",
    },
    {
      title: "How can I get started with Solnet?",
      text: "You can get started by signing up on our platform and following the onboarding process to set up your investment strategies.",
    },
  ];

  return (
    <div className="Questions" id="faq">
      <div className="Questions-title">
        <h1 className="Questions-title-text">Frequently Asked Questions</h1>
        <h2 className="Questions-title-subtext">
          Our AI algorithm detects anomalous transactions and user behavior by
          analyzing vast amounts of data and identifying potential threats in
          real-time.
        </h2>
      </div>
      <div className="Questions-content">
        {questionsData.map((item, index) => (
          <div className="Questions-content-item" key={index}>
            <h1 className="Questions-content-item-title">{item.title}</h1>
            <hr />
            <p className="Questions-content-item-text">{item.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Questions;
